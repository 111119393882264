@tailwind base;
@tailwind components;
@tailwind utilities;

[data-value] > input:focus {
  box-shadow: none
}

:root {
  --primary: #1975d2;
  --secondary: #a0aec0;
  --accent: #e8e8e8;
  --background: white;
  --main: #0d0106;
  --header: #2d3748;
  --white: #fff;
  --input-border: #d1d5db;
  --error: red;
  --tw-ring-color: transparent;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  background-color: white;
}

input,
textarea,
select {
  --tw-ring-color: transparent!important;
}